import Vue from 'vue';
import Vuex from 'vuex';
import {
  format, subDays, addDays, startOfWeek, endOfWeek, startOfDay,
} from 'date-fns';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    applicationClaims: null,
    overviewChosenStation: null,
    overseeKpiDisplayList: [],
    overseeKpiPrechooseList: [],
    overseeDisplayModal: true,
    chosenKpiForChart: null,
    kpiDisplayPeriodInDays: 7,
    kpiVariables: [],
    kpiDisplayOption: 'Last 7 days',
    yesterday: null,
    startOfLastSevenDays: null,
    startOfPreviousWeek: null,
    endOfPreviousWeek: null,
  },
  getters: {
    chartXArray(state) {
      const xArrary = [];
      if (state.kpiDisplayOption === 'Last 7 days') {
        for (let i = 7; i > 0; i -= 1) {
          xArrary.push(format(subDays(new Date(), i), 'yyyy/MM/dd'));
        }
        return xArrary;
      }
      const previousWeekStart = startOfWeek(subDays(new Date(), 7), { weekStartsOn: 1 });
      for (let i = 0; i < 7; i += 1) {
        xArrary.push(format(addDays(previousWeekStart, i), 'yyyy/MM/dd'));
      }
      return xArrary;
    },
  },
  mutations: {
    setDates(state, currentDate) {
      state.yesterday = subDays(currentDate, 1);
      const sevenDaysAgo = subDays(currentDate, 7);
      state.startOfLastSevenDays = startOfDay(subDays(currentDate, 7));
      state.startOfPreviousWeek = startOfWeek(sevenDaysAgo, { weekStartsOn: 1 });
      state.endOfPreviousWeek = endOfWeek(sevenDaysAgo, { weekStartsOn: 1 });
    },
    setApplicationClaims(state, claims) {
      state.applicationClaims = claims;
    },
    setOverviewChosenStation(state, overviewChosenStation) {
      state.overviewChosenStation = overviewChosenStation;
    },
    setOverseeKpiDisplayList(state, overseeKpiDisplayList) {
      state.overseeKpiDisplayList = overseeKpiDisplayList;
    },
    setOverseeKpiPrechooseList(state, overseeKpiPrechooseList) {
      state.overseeKpiPrechooseList = overseeKpiPrechooseList;
    },
    setOverseeDisplayModal(state, overseeDisplayModal) {
      state.overseeDisplayModal = overseeDisplayModal;
    },
    setChosenKpiForChart(state, chosenKpiForChart) {
      state.chosenKpiForChart = chosenKpiForChart;
    },
    setKpiDisplayPeriodInDays(state, kpiDisplayPeriodInDays) {
      state.kpiDisplayPeriodInDays = kpiDisplayPeriodInDays;
    },
    setKpiVariables(state, kpiVariables) {
      state.kpiVariables = kpiVariables;
    },
    setKpiDisplayOption(state, kpiDisplayOption) {
      state.kpiDisplayOption = kpiDisplayOption;
    },
  },
  actions: {
    setOverviewChosenStation(context, payload) {
      context.commit('setOverviewChosenStation', payload.overviewChosenStation);
    },
    setOverseeKpiDisplayList(context, payload) {
      context.commit('setOverseeKpiDisplayList', payload.overseeKpiDisplayList);
    },
    setOverseeKpiPrechooseList(context, payload) {
      context.commit('setOverseeKpiPrechooseList', payload.overseeKpiPrechooseList);
    },
    setOverseeDisplayModal(context, payload) {
      context.commit('setOverseeDisplayModal', payload.overseeDisplayModal);
    },
    setChosenKpiForChart(context, payload) {
      context.commit('setChosenKpiForChart', payload.chosenKpiForChart);
    },
    setKpiDisplayPeriodInDays(context, payload) {
      context.commit('setKpiDisplayPeriodInDays', payload.kpiDisplayPeriodInDays);
    },
    setKpiVariables(context, payload) {
      context.commit('setKpiVariables', payload.kpiVariables);
    },
    setKpiDisplayOption(context, payload) {
      context.commit('setKpiDisplayOption', payload.kpiDisplayOption);
    },
  },
  modules: {},
});
