import { render, staticRenderFns } from "./TheKpiChart.vue?vue&type=template&id=6ce7cfbc&scoped=true&"
import script from "./TheKpiChart.vue?vue&type=script&lang=js&"
export * from "./TheKpiChart.vue?vue&type=script&lang=js&"
import style0 from "./TheKpiChart.vue?vue&type=style&index=0&id=6ce7cfbc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ce7cfbc",
  null
  
)

export default component.exports