import STATIONS from '@/assets/data/stations.json';
import KPIS from '@/assets/data/KpiList.json';
import PIDS from '@/assets/data/pids.json';

export function getStations() {
  return STATIONS;
}

export function getKpis() {
  return KPIS;
}

export function getPids() {
  return PIDS;
}

export function getKpi(
  {
    name = '',
    search = '',
    type = [],
  },
) {
  return KPIS.filter(kpi => (
    (search === '' || kpi.label.toLowerCase().includes(search) || kpi.category.toLowerCase().includes(search))
    && (name === '' || kpi.name === name.toLowerCase())
    && (type.length === 0 || type.includes(kpi.type))
  ));
}

export function getKpiTypes() {
  return KPIS.map(kpi => kpi.type).filter((value, index, self) => (self.indexOf(value) === index
  ));
}

export function getStation({ name = '', ids = [] }) {
  return STATIONS.filter(
    v => (name === '' || v.name.toLowerCase().includes(name))
      && (ids.length === 0 || ids.includes(v.id)),
  );
}

export function getPid(
  {
    name = '',
  },
) {
  return PIDS.filter(pid => (name === '' || pid.name.toLowerCase() === name.toLowerCase()));
}
