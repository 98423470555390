<template>
  <div class="station-map box">
    <l-map
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      class="map"
      ref="lmap"
    >
      <l-control position="topcenter">
        <div class="tabs is-centered is-toggle is-toggle-rounded map-tabs is-small">
        <ul>
          <li :class="{ 'is-active': !capacity }" @click="capacity = false">
            <a :class="{'tab-inactive': capacity}">
              <span class="mr-2"><i class="far fa-check-circle"></i></span>
              <span>Availability</span>
            </a>
          </li>
          <li :class="{ 'is-active': capacity }" @click="capacity = true">
            <a :class="{'tab-inactive': !capacity}">
              <span class="mr-2"><i class="fas fa-database"></i></span>
              <span>Capacity</span>
            </a>
          </li>
        </ul>
      </div>
      </l-control>
      <l-control position="topright" class="map-legend box">
        <button
          class="legend-button button"
          v-if="!openLegend"
          @click="openLegend = !openLegend"
          key="fa-angle-left"
        >
          <span class="icon">
            <i class="fas fa-angle-left"></i>
          </span>
        </button>
        <button
          class="legend-button opened-legend-button button"
          v-if="openLegend"
          @click="openLegend = !openLegend"
          key="fa-angle-right"
        >
          <span class="is-size-7" v-if="!capacity">Status</span>
          <span class="is-size-7" v-else>Capacity and Pressure</span>
          <span class="icon">
            <i class="fas fa-angle-right"></i>
          </span>
        </button>
        <CapacityLegends :show="openLegend && capacity" />
        <AvailabilityLegends :show="openLegend && !capacity" />
      </l-control>
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-feature-group ref="feature">
        <l-marker
          v-for="station in stations"
          :key="station.id"
          :lat-lng="getPosition(station.lat, station.lon)"
          :ref="station.id"
          @click="
            chooseStation(
              station.id,
              getPosition(station.lat, station.lon),
              station.m_capacity
            )
          "
          :icon="getIcon(station.available, station.m_capacity, station.p)"
        >
          <l-popup class="map-popup" :options="popupOptions">
            <div>
              <!-- <figure class="image">
                <img
                  :src="require(`@/assets/images/Stations/${getStationPopupImage(station.name)}`)"
                  alt="station-image"
                />
              </figure> -->
              <ul>
                <li>
                  <span class="primary-text">{{ station.name }}</span>
                </li>
                <li>
                  <strong>Pressure: </strong
                  >{{ getPressureLabel(station.p) }} bar
                </li>
                <li>
                  <strong>Vehicle type: </strong> {{getStationVehicleType(station.name)}}
                </li>
                <li v-if="station.available">
                  <strong>Available since: </strong>{{getAvailabilitySince(true)}}
                </li>
                <li v-else>
                  <strong>Unavailable since: </strong>{{getAvailabilitySince(false)}}
                </li>
              </ul>
            </div>
          </l-popup>
        </l-marker>
      </l-feature-group>
      <l-feature-group @ready="mapReadyCallback">
      <l-circle-marker
        v-if="chosenPosition !== null && capacity"
        :lat-lng="chosenPosition"
        :radius="chosenRadius"
        color="#FFFFFF"
      />
      <l-marker
        v-if="chosenPosition !== null && !capacity"
        :lat-lng="chosenPosition"
        :icon="iconChosen"
      />
      </l-feature-group>
    </l-map>
  </div>
</template>

<script>
import L, { latLng, icon } from 'leaflet';
import {
  LMap,
  LTileLayer,
  LMarker,
  LControl,
  LCircleMarker,
  LPopup,
  LFeatureGroup,
} from 'vue2-leaflet';
import CapacityLegends from '@/components/Oversee/Maps/CapacityLegends.vue';
import AvailabilityLegends from '@/components/Oversee/Maps/AvailabilityLegends.vue';
import gql from 'graphql-tag';
import { getPid } from '@/assets/data/queries';
import {
  format, subDays,
} from 'date-fns';

export default {
  name: 'TheStationMap',

  components: {
    LMap,
    LTileLayer,
    LFeatureGroup,
    LMarker,
    LControl,
    LPopup,
    LCircleMarker,
    CapacityLegends,
    AvailabilityLegends,
  },

  data() {
    return {
      map: null,
      featureGroup: null,
      boundsToFit: null,
      capacity: false,
      zoom: 5,
      stations: null,
      center: latLng(45.747633, 4.857524),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: null,
      currentCenter: null,
      mapOptions: {
        zoomSnap: 0.5,
      },
      iconUnavailable: icon({
        // eslint-disable-next-line
        iconUrl: require('@/assets/images/Oversee/marker-unavailable.svg'),
        iconSize: [32, 37],
        iconAnchor: [16, 37],
        popupAnchor: [0, -34],
      }),
      iconAvailable: icon({
        // eslint-disable-next-line
        iconUrl: require('@/assets/images/Oversee/marker-available.svg'),
        iconSize: [32, 37],
        iconAnchor: [16, 37],
        popupAnchor: [0, -34],
      }),
      icon700Large: icon({
        // eslint-disable-next-line
        iconUrl: require('@/assets/images/Oversee/marker700.svg'),
        iconSize: [50, 50],
        iconAnchor: [25, 25],
      }),
      icon700Medium: icon({
        // eslint-disable-next-line
        iconUrl: require('@/assets/images/Oversee/marker700.svg'),
        iconSize: [36, 36],
        iconAnchor: [18, 18],
      }),
      icon700Small: icon({
        // eslint-disable-next-line
        iconUrl: require('@/assets/images/Oversee/marker700.svg'),
        iconSize: [20, 20],
        iconAnchor: [10, 10],
      }),
      icon350Large: icon({
        // eslint-disable-next-line
        iconUrl: require('@/assets/images/Oversee/marker350.svg'),
        iconSize: [50, 50],
        iconAnchor: [25, 25],
      }),
      icon350Medium: icon({
        // eslint-disable-next-line
        iconUrl: require('@/assets/images/Oversee/marker350.svg'),
        iconSize: [36, 36],
        iconAnchor: [18, 18],
      }),
      icon350Small: icon({
        // eslint-disable-next-line
        iconUrl: require('@/assets/images/Oversee/marker350.svg'),
        iconSize: [20, 20],
        iconAnchor: [10, 10],
      }),
      iconMixLarge: icon({
        // eslint-disable-next-line
        iconUrl: require('@/assets/images/Oversee/markermix.png'),
        iconSize: [50, 50],
        iconAnchor: [25, 25],
      }),
      iconMixMedium: icon({
        // eslint-disable-next-line
        iconUrl: require('@/assets/images/Oversee/markermix.png'),
        iconSize: [36, 36],
        iconAnchor: [18, 18],
      }),
      iconMixSmall: icon({
        // eslint-disable-next-line
        iconUrl: require('@/assets/images/Oversee/markermix.png'),
        iconSize: [20, 20],
        iconAnchor: [10, 10],
      }),
      iconChosen: icon({
        // eslint-disable-next-line
        iconUrl: require('@/assets/images/Oversee/markerchosen.svg'),
        iconSize: [24, 24],
        iconAnchor: [12, 12],
      }),
      openLegend: true,
      chosenPosition: null,
      chosenCapacity: 100,
      popupOptions: {
        maxWidth: 150,
        minWidth: 150,
        className: 'map-popup',
      },
    };
  },

  computed: {
    overviewChosenStation() {
      return this.$store.state.overviewChosenStation;
    },
    chosenRadius() {
      if (this.chosenCapacity < 350) {
        return 11;
      }
      if (this.chosenCapacity < 1000) {
        return 19;
      }
      return 26;
    },
    checkBound() {
      return this.boundsToFit.isValid();
    },
  },

  mounted() {
    this.map = this.$refs.lmap.mapObject;
    this.addControlPlaceholders(this.map);
  },

  watch: {
    overviewChosenStation() {
      if (this.overviewChosenStation === null) {
        this.chosenPosition = null;
        this.map.closePopup();
        this.fitBounds();
        setTimeout(this.resizeMap, 500);
        this.openLegend = true;
      } else {
        this.$refs[this.overviewChosenStation][0].mapObject.openPopup();
        this.chosenPosition = this.$refs[this.overviewChosenStation][0].mapObject.getLatLng();
        this.map.flyTo(this.chosenPosition, 6);
        this.chosenCapacity = this.stations.filter(
          station => station.id === this.overviewChosenStation,
        )[0].m_capacity;
        setTimeout(this.resizeMap, 500);
        this.openLegend = false;
      }
    },
  },

  apollo: {
    $client: 'apolloClientMonitoring',
    stations: {
      query: gql`
        query stations {
          stations {
            id
            name
            lat
            lon
            p
            m_capacity
            available
          }
        }
      `,
    },
  },

  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    chooseStation(overviewChosenStation, chosenPosition, chosenCapacity) {
      this.$store.dispatch('setOverviewChosenStation', {
        overviewChosenStation,
      });
      this.chosenPosition = chosenPosition;
      this.chosenCapacity = chosenCapacity;
    },
    getPosition(lat, lng) {
      return latLng(lat, lng);
    },
    getIconAvailability(available) {
      return available ? this.iconAvailable : this.iconUnavailable;
    },
    getIconCapacity(capacity, pressure) {
      if (pressure.length > 1 || pressure[0] === 600) {
        if (capacity < 350) {
          return this.iconMixSmall;
        }
        if (capacity < 1000) {
          return this.iconMixMedium;
        }
        return this.iconMixLarge;
      }
      if (pressure[0] === 350) {
        if (capacity < 350) {
          return this.icon350Small;
        }
        if (capacity < 1000) {
          return this.icon350Medium;
        }
        return this.icon350Large;
      }
      if (capacity < 350) {
        return this.icon700Small;
      }
      if (capacity < 1000) {
        return this.icon700Medium;
      }
      return this.icon700Large;
    },
    getIcon(available, capacity, pressure) {
      return this.capacity
        ? this.getIconCapacity(capacity, pressure)
        : this.getIconAvailability(available);
    },
    getPressureLabel(pressure) {
      if (pressure.length > 1) return `${pressure[0]}, ${pressure[1]}`;
      if (pressure[0] === 600) return '350, 700';
      return pressure[0];
    },
    fitBounds() {
      if (this.checkBound) {
        this.map.fitBounds(this.boundsToFit, { padding: [150, 150] });
      }
    },
    addControlPlaceholders(map) {
      // eslint-disable-next-line no-underscore-dangle
      const corners = map._controlCorners;
      const l = 'leaflet-';
      // eslint-disable-next-line no-underscore-dangle
      const container = map._controlContainer;

      function createCorner(vSide, hSide) {
        const className = `${l + vSide} ${l}${hSide}`;

        corners[vSide + hSide] = L.DomUtil.create('div', className, container);
      }

      createCorner('top', 'center');
    },
    resizeMap() {
      this.map.invalidateSize();
    },
    getStationVehicleType(stationName) {
      return getPid({ name: stationName })[0]
        ? getPid({ name: stationName })[0].vehicle_type
        : null;
    },
    getStationPopupImage(stationName) {
      return getPid({ name: stationName })[0]
        ? getPid({ name: stationName })[0].popup
        : null;
    },
    getAvailabilitySince(available) {
      return available
        ? format(subDays(new Date(), 200), 'yyyy/MM/dd')
        : format(subDays(new Date(), 8), 'yyyy/MM/dd');
    },
    mapReadyCallback() {
      this.featureGroup = this.$refs.feature.mapObject;
      this.boundsToFit = this.featureGroup.getBounds();
      setTimeout(() => {
        this.boundsToFit = this.featureGroup.getBounds();
        this.fitBounds();
      }, 800);
    },
  },
};
</script>

<style lang="scss" scoped>
.station-map {
  text-align: center;
  height: 100%;
  border-radius: 20px !important;
  padding: 0 !important;
}
.map {
  border-radius: inherit;
  z-index: 0;
}
.marker {
  width: 1rem;
}
.map-legend {
  text-align: end;
  padding: 0;
  font-family: "Roboto", sans-serif !important;
  border-radius: 10px;
}
.legend-button {
  border: none;
  background-color: white;
  height: 2rem;
  border-radius: 10px;
}
.opened-legend-button {
  width: 100%;
  background-color: #034fa1;
  color: white !important;
  justify-content: space-between;
}
.medium {
  height: 36px;
  width: 36px;
  margin: auto;
}
.primary-text {
  color: $oversee;
  font-weight: bold;
}
.leaflet-container {
  font-family: "Roboto", sans-serif !important;
}
.map-popup {
  border-radius: 0 !important;
  font-family: "Roboto", sans-serif !important;
}
.tab-inactive{
  background-color: white;
}
</style>
