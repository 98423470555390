<template>
  <div class="chart" v-if="station !== null">
    <Plotly :layout="fleet_layout" :data="chartData" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { Plotly } from 'vue-plotly';
import { addDays, formatISO } from 'date-fns';
import { mapGetters } from 'vuex';

export default {
  name: 'TheKpiChart',

  components: {
    Plotly,
  },

  data() {
    return {
      station: null,
      dailyValueArray: null,
    };
  },

  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(['chartXArray']),
    chartData() {
      return [
        {
          x: this.chartXArray,
          y: this.dailyValueArray,
          type: 'scatter',
          mode: 'lines',
          line: {
            width: 3,
          },
        },
      ];
    },
    kpiName() {
      return this.$store.state.chosenKpiForChart;
    },
    kpiLabel() {
      return this.theVariable.label;
    },
    kpiUnit() {
      return this.theVariable.type === 'ratio' ? '%' : this.theVariable.unit;
    },
    theVariable() {
      return this.$store.state.kpiVariables.filter(kpi => kpi.name === this.kpiName)[0];
    },
    overviewChosenStation() {
      return this.$store.state.overviewChosenStation;
    },
    kpiDisplayOption() {
      return this.$store.state.kpiDisplayOption;
    },
    rawDailyData() {
      return this.station === null ? [] : this.station.daily_values;
    },
    fleet_layout() {
      return {
        title: {
          text: this.kpiLabel,
          font: {
            color: '#034FA1',
            family: 'Roboto',
          },
        },
        margin: {
          pad: 15,
        },
        yaxis: {
          title: this.kpiUnit,
          automargin: true,
          rangemode: 'tozero',
        },
        xaxis: {
          title: {
            text: 'Time',
            standoff: 10,
          },
          automargin: true,
        },
        showlegend: false,
        paper_bgcolor: 'rgba(255,255,255,0)',
        annotations: [],
      };
    },
    period_filter() {
      if (this.$store.state.kpiDisplayOption === 'Last 7 days') {
        return {
          since: this.$store.state.startOfLastSevenDays,
          until: this.$store.state.yesterday,
        };
      }
      return {
        since: this.$store.state.startOfPreviousWeek,
        until: this.$store.state.endOfPreviousWeek,
      };
    },
  },

  apollo: {
    $client: 'apolloClientMonitoring',
    station: {
      query: gql`
        query station($id: String!, $names: [String], $since: DateTime, $until: DateTime) {
          station(id: $id) {
            id
            name
            source_available
            dispenser_available
            core_available
            daily_values(names: $names, since: $since, until: $until) {
              name
              timestamp
              value
            }
          }
        }
      `,
      variables() {
        return {
          id: this.overviewChosenStation,
          names: [this.kpiName],
          since: this.period_filter.since,
          until: this.period_filter.until,
        };
      },
      skip() {
        return this.overviewChosenStation === null;
      },
    },
  },

  watch: {
    rawDailyData() {
      if (this.station !== null) {
        this.updateDailyValues();
      }
    },
  },

  methods: {
    updateDailyValues() {
      const dailyValueArray = [];
      if (this.kpiDisplayOption === 'Last 7 days') {
        for (let i = 0; i < 7; i += 1) {
          let dailyValue = null;
          this.rawDailyData.forEach((element) => {
            if (
              element.timestamp === formatISO(addDays(this.$store.state.startOfLastSevenDays, i))
            ) {
              dailyValue = this.theVariable.type === 'ratio' ? element.value * 100 : element.value;
            }
          });
          dailyValueArray.push(dailyValue);
          dailyValue = null;
        }
      } else {
        for (let i = 0; i < 7; i += 1) {
          let dailyValue = null;
          this.rawDailyData.forEach((element) => {
            if (
              element.timestamp === formatISO(addDays(this.$store.state.startOfPreviousWeek, i))
            ) {
              dailyValue = this.theVariable.type === 'ratio' ? element.value * 100 : element.value;
            }
          });
          dailyValueArray.push(dailyValue);
          dailyValue = null;
        }
      }
      this.dailyValueArray = dailyValueArray;
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  height: 100%;
}
.chart div {
  height: 100%;
}
</style>
