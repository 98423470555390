<template>
  <div class="pfd-sidebar">
    <ul class="menu-list">
      <li>
        <SidebarLink to="pfd-description" label="All Stations" />
        <ul class="menu-list">
          <li>
            <SidebarLink
              v-for="station in stations"
              :key="station.id"
              to="pfd"
              idParam="id"
              :id="station.id"
              :label="station.name"
            />
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import SidebarLink from '@/components/SidebarLink.vue';

export default {
  name: 'PfdSidebar',

  components: {
    SidebarLink,
  },

  props: {
    stations: {
      require: true,
      default: [],
    },
  },

  data() {
    return {
    };
  },

  methods: {
    resetStation(overviewChosenStation) {
      this.$store.dispatch('setOverviewChosenStation', {
        overviewChosenStation,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pfd-sidebar {
  width: 100%;
}
</style>
