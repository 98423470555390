<template>
  <div class="position indicater">
    <div v-if="subSystem">
      <figure class="image">
        <img src="@/assets/images/Stations/section_available.svg" alt="icon-available">
      </figure>
    </div>
    <div v-else>
      <figure class="image">
        <img src="@/assets/images/Stations/section_unavailable.svg" alt="icon-unavailable">
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PidIndicater',

  props: {
    subSystem: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      more: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.position {
  position: absolute;
}
.indicater {
  position:absolute;
  height: 1.8rem;
  width: 1.8rem;
  cursor: pointer;
  z-index: 5;
}
</style>
