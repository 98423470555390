<template>
  <div>
    <p class="notification is-info" v-html="text"/>
    <button
      v-if="$route.name === 'about' && !authState.isAuthenticated"
      class="button is-rounded is-success"
      @click="login"
    >
      Login to get started
    </button>
  </div>
</template>

<script>
export default {
  name: 'TheAbout',

  computed: {
    text() {
      if (this.$route.name === 'home') {
        return 'FilHy is a smart plug-in to off-the-shelf refuelling management solutions '
          + 'providing advanced control features and extensive services to enhance the management '
          + 'of HRS networks.';
      }
      return 'Section under developpement';
    },
  },

  methods: {
    async login() {
      await this.$auth.signInWithRedirect();
    },
  },
};
</script>

<style scoped>
div {
  width: 60%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15%;
}

p {
  font-size: 20px;
}
</style>
