<template>
  <div class="legal-statement container is-max-widescreen">
    <article>
      <header class="title has-text-left has-text-black my-6">
        Legal Statement
      </header>
      <section class="has-text-left has-text-black">
        <p>PERSEE</p>
        <p>12 place Fontaine 39 130 PONT-DE-POITTE, France</p>
        <p>Email: contact@pers-ee.com</p>
        <p>+33 3 84 48 31 10 (standard cost rate)</p>
      </section>
      <section class="mt-6 has-text-left has-text-black">
        <p><strong>Trade registry:</strong> Lons Le Saunier 790 596 282</p>
        <p><strong>Share Capital:</strong> 168.900 Euros</p>
        <p><strong>VAT number:</strong> FR51790596282</p>
        <p><strong>Director of Publication:</strong> Laurence GRAND-CLEMENT</p>
        <p><strong>Hosting Company:</strong> FR</p>
      </section>
      <section class="mt-6 has-text-left has-text-black">
        <header class="title has-text-info is-size-4">
          Terms of Use of the Website
        </header>
        <p class="py-3">
          The Terms of Use of filhy.hy-suite.com, hy-suite.com and all related
          Servers and other websites operated by PERSEE (the “<b>Website</b>”)
          set forth the terms and conditions under which one (the “<b>User</b>”)
          may use the Website and in particular the rights of use of the
          information, content and data provided on the Website and the rights,
          obligations and liability of both PERSEE and the User in this respect.
          <b>Liability of PERSEE</b> The information, content and data provided
          through the Website are made available for information purposes only ;
          they are not legally binding and do not constitute any advice. They
          are in no way intended as a mean of soliciting business or offering
          securities to any person, in any country, directly or indirectly. The
          User uses the information, content and data available at its own risks
          and under its own responsibility. PERSEE provides its best efforts to
          ensure the accuracy of the information, content and data available on
          its Website. PERSEE may not be held liable for any loss or damage
          caused by the incompleteness or incorrectness of the information,
          content and data available on its Website. PERSEE may not be held
          liable for any loss, damages, costs or expenses arising from, amongst
          others, errors, omissions or alterations, in the information, content
          and data that is available on its Website. PERSEE may not be held
          liable for loss or damage caused by the actions of third parties taken
          on the basis of information, content and data available on its
          Website. PERSEE may not be held liable for loss or damage resulting
          from the temporary or permanent, full or partial unavailability of its
          Website.
        </p>
      </section>
      <section class="mt-6 has-text-left has-text-black">
        <header class="title is-size-4 has-text-info">
          Liability of the User
        </header>
        <p class="py-3">
          The access and use of the Website requests the unconditional
          acceptance by the User of these Terms of Use. The User also agrees to
          comply with all applicable laws and regulations, including not to
          provide or disclose any information, content, image or message that
          may be considered illegal under these provisions.
        </p>
        <p class="py-3">
          The User also agrees to respect the rights of third parties including
          intellectual property and privacy rights.
        </p>
        <p class="py-3">
          The User agrees further not to alter the operation of the Website and
          computer systems, nor to usurp or deflect the identifiers, names,
          attributes of other Users, and generally not to achieve in any way any
          action likely to create confusion in the minds of Users.
        </p>
        <p class="py-3">
          The User also undertakes not to adopt a behavior likely to harm the
          image, interests and rights of PERSEE or of a third party, or to carry
          out any action likely to damage, alter, interrupt, overload, hinder
          the normal operation of the Website. In case of any breach by the User
          of these Terms of Use, PERSEE reserves the right to limit, suspend and
          stop the access of the User to the Website and to take all the
          technical measures to that effect.
        </p>
        <p class="py-3">
          The User is solely responsible for the use he/she makes of the
          services available on the Website, whether in free access without
          identification or from his/her account. The User is also responsible
          for the contents, messages, information or images that he/she provides
          and will therefore be held liable for any direct or indirect damage of
          any nature whatsoever, caused by any use of the Website or any other
          breach of these Terms of Use.
        </p>
        <p class="py-3">
          These Terms of Use are governed by French law. All disputes arising
          out of or in connection with these Terms of Use shall be submitted to
          the CMAP (Centre for Mediation and Arbitration of Paris, Paris Chamber
          of Commerce and Industry - 39, avenue Franklin D. Roosevelt, 75008
          Paris) and, in the event that no settlement is thereby reached, to the
          Paris Mediation and Arbitration Centre (CMAP) Rules of Arbitration, to
          which the Parties undertake to adhere, by three arbitrators appointed
          in accordance with the said Rules. The arbitration shall be conducted
          in English.
        </p>
        <p class="py-3">
          Nothing in the provisions above shall preclude the Parties from
          applying for interim or conservatory measures or any other injunctive
          relief in summary proceedings before the competent courts. The
          application of a Party to a judicial authority for such measures or
          for the implementation of any interim or conservatory measures ordered
          by the arbitration tribunal shall not be deemed as an infringement or
          a waiver of the arbitration agreement and shall not affect the
          relevant powers reserved to the arbitration tribunal. Any order or
          provision issued by the judicial authority must be notified without
          delay to the arbitrators.
        </p>
      </section>
      <section class="mt-6 has-text-left has-text-black">
        <header class="title is-size-4 has-text-info">Use of data</header>
        <p class="py-3">
          PERSEE has the exclusive control on any data (in particular market
          data such as prices) published on its Website. The User may not
          download, reproduce, license, cease or use it for commercial purposes
          (inter alia for indexation purposes) without the explicit prior
          approval of PERSEE , which may require to enter into a dedicated
          written agreement with PERSEE or at least, accept the general
          conditions for the use of market data. The information found on the
          Website is for the personal use of individual visitors only.
        </p>
      </section>
      <section class="mt-6 has-text-left has-text-black">
        <header class="title is-size-4 has-text-info">Links</header>
        <p class="py-3">
          The reproduction, copying, duplication, transfer or creation of
          hypertext links, in any manner, of all or part of the information that
          appears on the Website also requires the prior written approval of
          PERSEE . PERSEE reserves all rights to request the deletion or disable
          links or frame of any third party websites containing inappropriate,
          profane, defamatory, infringing, obscene, indecent or unlawful topics,
          names, material or information, or material or information that
          violates any written law, any applicable intellectual property,
          proprietary, privacy or publicity right.
        </p>
      </section>
      <section class="mt-6 has-text-left has-text-black">
        <header class="title is-size-4 has-text-info">
          Website and content copyright
        </header>
        <p class="py-3">
          The design of the Website and the text, images, graphics, layout,
          audio documents and video clips as well as databases contained herein
          are protected by copyright and are owned by PERSEE, except when
          otherwise stated. Neither the Website nor the contents made available
          therein (including; graphic representations, audio and video clips,
          html code, buttons and text) may be copied, reprinted, published,
          transmitted, transferred, disseminated or distributed in any manner
          without the prior written approval of PERSEE. However, the preparation
          of a single copy for exclusive personal, non-commercial use by
          downloading into an individual personal computer (including permanent
          or temporary saving on said PC, displaying of the contents in the
          offline mode and the preparation of a print-out of parts of the
          content) are expressly permitted. However, this shall be subject to
          the precondition that such activity does not involve changing the
          Website and its content and that all references to copyrights,
          patents, trademarks and other property rights are contained in the
          copies made or that a corresponding reference is inserted. Any
          commercial use shall only be permitted with the express written
          approval of PERSEE. The authors of the Website comply with the
          copyright law regarding the graphics and texts used in all
          publications. They use graphics and text which they have developed
          themselves or graphics and texts which are not subject to a license.
          As soon as knowledge of an infringement of copyright is gained, such
          contents are removed forthwith.
        </p>
        <p class="py-3">
          The content of the Website cannot be construed as conferring a license
          and/or the title to a copyright, patent, trademark or any other
          property right from PERSEE or a third party in any way. TEDHY and
          FILHY are a trademark registered by PERSEE. The use of the registered
          trademarks of EPEX SPOT SE is not permitted without the prior written
          permission of PERSEE.
        </p>
      </section>
      <section class="mt-6 has-text-left has-text-black">
        <header class="title is-size-4 has-text-info">
          Applicable law and validity
        </header>
        <p class="py-3">
          The use of the Website and these Terms of Use are governed by French
          law. The ineffectiveness or invalidity of individual phrases within
          this text shall not affect the validity of the remainder of the text.
        </p>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  name: 'LegalStatement',

  mounted() {
    this.scrollToTop();
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.title{
  padding-top: 1.5rem;
}
</style>
