<template>
  <div class="oversee-kpis box" :class="{ 'show-chart': displayChart }">
    <div class="level kpi-options mb-4">
      <div class="level-left"></div>
      <div class="level-right">
        <div
          class="dropdown level-item"
          :class="{ 'is-active': dropdownActive }"
        >
          <div class="dropdown-trigger">
            <button
              class="button time-option-button is-rounded"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              @click="dropdownActive = !dropdownActive"
            >
              <span class="icon">
                <i class="fas fa-clock"></i>
              </span>
              <span class="mx-2" v-text="$store.state.kpiDisplayOption"></span>
              <span v-show="!dropdownActive" class="icon m-0">
                <i class="fas fa-caret-down"></i>
              </span>
              <span v-show="dropdownActive" class="icon m-0">
                <i class="fas fa-caret-up"></i>
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content has-text-left">
              <a class="dropdown-item" @click="choseTime('Last 7 days')">
                <span class="time-option">Last 7 days</span>
              </a>
              <a class="dropdown-item" @click="choseTime('Previous week')">
                <span class="time-option">Last calendar week</span>
              </a>
            </div>
          </div>
        </div>

        <button
          class="button level-item to-kpipage"
          @click="goToKpiPage"
        >
          <span class="icon">
            <i class="fas fa-cog"></i>
          </span>
        </button>
      </div>
    </div>
    <div v-if="isNotEmpty(station)">
      <div class="columns is-multiline is-flex" v-if="kpis.length > 0">
        <div class="column is-half" v-for="kpi in kpis" :key="kpi.name">
          <KpiDisplayRow
            :name="kpi.name"
            :value="kpi.value"
            :alarm="kpi.value < 0"
            :selected="kpi.name === chosenKpiForChart"
            @click.native="selectKpi(kpi.name)"
          />
        </div>
      </div>
      <div v-else>
        <h4 class="title is-size-5">No value, configure the shown kpis</h4>
      </div>
    </div>
    <div v-else>
        <h4 class="title is-size-5">Loading</h4>
    </div>
    <div class="level mt-3">
      <div class="level-left"></div>
      <div class="level-right">
        <button class="button link-button more-button level-item mt-3 is-rounded">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://supervision.filhy.hy-suite.com/"
            >+ More</a
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import KpiDisplayRow from '@/components/Oversee/Maps/KpiDisplayRow.vue';
import gql from 'graphql-tag';

export default {
  name: 'TheOverviewKpis',

  components: {
    KpiDisplayRow,
  },

  data() {
    return {
      station: null,
      dropdownActive: false,
      selectedKpi: null,
      variables: null,
    };
  },

  computed: {
    overviewChosenStation() {
      return this.$store.state.overviewChosenStation;
    },
    kpisNameChosen() {
      return this.$store.state.overseeKpiDisplayList;
    },
    chosenKpiForChart() {
      return this.$store.state.chosenKpiForChart;
    },
    displayChart() {
      return this.chosenKpiForChart !== null;
    },
    kpis() {
      return this.station.weekly_values.filter(
        kpi => this.kpisNameChosen.includes(kpi.name.toLowerCase()),
      );
    },
    averageKpis() {
      return this.variables === null
        ? []
        : this.variables.filter(kpi => this.kpisNameChosen.includes(kpi.name.toLowerCase()));
    },
    date_filter() {
      if (this.$store.state.kpiDisplayOption === 'Last 7 days') {
        return this.$store.state.startOfLastSevenDays;
      }
      return this.$store.state.startOfPreviousWeek;
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$store.state.overseeKpiDisplayList.length === 0 && this.variables !== null) {
        let selectedKpiList = [];
        selectedKpiList = this.variables.slice(0, 6).map(kpi => kpi.name);
        this.$store.dispatch('setOverseeKpiDisplayList', {
          overseeKpiDisplayList: selectedKpiList,
        });
        if (this.$store.state.kpiVariables.length === 0 && this.variables !== null) {
          this.$store.dispatch('setKpiVariables', {
            kpiVariables: this.variables,
          });
        }
      }
    });
  },

  watch: {
    variables() {
      if (this.$store.state.overseeKpiDisplayList.length === 0 && this.variables !== null) {
        let selectedKpiList = [];
        selectedKpiList = this.variables.slice(0, 6).map(kpi => kpi.name);
        this.$store.dispatch('setOverseeKpiDisplayList', {
          overseeKpiDisplayList: selectedKpiList,
        });
      }
      if (this.$store.state.kpiVariables.length === 0 && this.variables !== null) {
        this.$store.dispatch('setKpiVariables', {
          kpiVariables: this.variables,
        });
      }
    },
  },

  apollo: {
    $client: 'apolloClientMonitoring',
    station: {
      query: gql`
        query station($id: String!, $at: DateTime!, $names: [String]!) {
          station(id: $id) {
            id
            name
            source_available
            core_available
            weekly_values(at: $at, names: $names) {
              name
              value
              timestamp
            }
          }
        }
      `,
      variables() {
        return {
          id: this.overviewChosenStation,
          at: this.date_filter,
          names: this.kpisNameChosen,
        };
      },
      skip() {
        return this.overviewChosenStation === null;
      },
    },
    variables: {
      query: gql`
        query variables {
          variables {
            id
            name
            label
            description
            unit
            category
            type
            max_threshold
            min_threshold
          }
        }
      `,
    },
  },

  methods: {
    isNotEmpty(val) {
      return val !== null && val !== '';
    },
    selectKpi(kpiName) {
      this.selectedKpi = this.chosenKpiForChart === kpiName ? null : kpiName;
      this.$store.dispatch('setChosenKpiForChart', {
        chosenKpiForChart: this.selectedKpi,
      });
    },
    choseTime(displayOption) {
      this.dropdownActive = false;
      this.setKpiDisplayOption(displayOption);
    },
    setKpiDisplayOption(displayOption) {
      this.$store.dispatch('setKpiDisplayOption', {
        kpiDisplayOption: displayOption,
      });
    },
    goToKpiPage() {
      this.$router.push({ name: 'kpiPage' });
    },
  },
};
</script>

<style lang="scss" scoped>
.oversee-kpis {
  text-align: center;
  height: 100%;
  padding: 10px 1.5rem !important;
  border-radius: 13px !important;
}
.show-chart {
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.show-chart::-webkit-scrollbar{
  display: none;
}
.time-option {
  margin-left: 1.25rem;
}
.clickable {
  cursor: pointer;
}

.to-kpipage{
  border-radius: 50%;
  width: 2rem;
  height: 2rem !important;
  color: #D1D1D1;
}
.to-kpipage:hover{
  color: #6F7070;
  background-color: rgba(117, 117, 117, 0.10) !important;
}
.time-option-button{
  background-color: $oversee !important;
  color: white !important;
  height: 2rem;
}
.more-button{
  height: 2rem;
}
</style>
