<template>
  <div>
    <figure class="image">
      <img
        :src="require(`@/assets/images/Stations/${pidImage}`)"
        alt="station_image"
      />
      <SectionPfdSensor
        v-for="pt in pts"
        :key="pt.name"
        :style="getPosition(pt.left, pt.top)"
        :name="pt.name"
        type="P"
        :max="pt.max"
        :min="pt.min"
        :al="pt.al"
        :ah="pt.ah"
      />
      <SectionPfdSensor
        v-for="tt in tts"
        :key="tt.name"
        :style="getPosition(tt.left, tt.top)"
        :name="tt.name"
        type="T"
        :max="tt.max"
        :min="tt.min"
        :al="tt.al"
        :ah="tt.ah"
      />
      <SectionPfdValve
        v-for="sov in sovs"
        :key="sov.name"
        :style="getPosition(sov.left, sov.top)"
        :name="sov.name"
      />
    </figure>
  </div>
</template>

<script>
import { getPid } from '@/assets/data/queries';
import SectionPfdSensor from '@/components/Oversee/Pfds/SectionPfdSensor.vue';
import SectionPfdValve from '@/components/Oversee/Pfds/SectionPfdValve.vue';

export default {
  name: 'SectionPfd',

  components: {
    SectionPfdSensor,
    SectionPfdValve,
  },

  data() {
    return {
    };
  },

  computed: {
    stationName() {
      return this.$route.params.station;
    },
    pid() {
      return getPid({ name: this.stationName })[0];
    },
    pidImage() {
      return this.pid.core.pfd === '' ? 'station1_torrance.png' : this.pid.core.pfd;
    },
    pts() {
      return this.pid.core.elements.filter(pt => (pt.type === 'pt'));
    },
    tts() {
      return this.pid.core.elements.filter(tt => (tt.type === 'tt'));
    },
    sovs() {
      return this.pid.core.elements.filter(sov => (sov.type === 'sov'));
    },
  },

  methods: {
    getPosition(left, top) {
      return `left: ${left}%; top: ${top}%;`;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
