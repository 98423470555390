<template>
  <nav
    id="navbar"
    class="navbar"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <div class="navbar-item">
        <img
          src="../assets/images/filhy_logo.svg"
          id="navbar-logo"
          alt="logo SopHy"
        />
      </div>
      <div
        class="display-sidebar"
      >
        <button class="button is-light"
                :class="buttonState"
                @click="displaySidebar()"
        ><span v-html="buttonIcon"/>{{buttonAction}} Sidebar
        </button>
      </div>
      <a
        role="button"
        id="navigationBurger"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="main-navigation"
        @click="toggle()"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div
      id="main-navigation"
      class="navbar-menu"
    >
        <div class="navbar-start">
          <NavbarLink
            :to="'overview'"
            :name="'maps&kpis'"
            :disabled="false"
            :class="{'unavailable': false}"
          >
            Maps &#38; KPIs
          </NavbarLink>
          <NavbarLink
            :to="'pfd-description'"
            :name="'pfds'"
            :disabled="false"
            :class="{'unavailable': false}"
          >
            PFDs
          </NavbarLink>
        <a class="navbar-item" :href="linkToSimulation">
        Simulate
        </a>
        <a class="navbar-item" :href="linkToCompare">
        Compare
        </a>
        </div>
        <div class="navbar-end" id="signIn-buttons">
          <div v-if="isAuthenticated"
               class="field is-grouped"
          >
            <p v-if="$store.state.applicationClaims"
               id="user-info"
            >
              <i class="far fa-user"/>
              {{$store.state.applicationClaims.given_name}}
            </p>
            <button
              class="button is-danger is-light"
              v-on:click='logout'
              id='logout-button'
            >
              Logout
            </button>
          </div>
          <button v-else class="button is-success is-light"
                  v-on:click='login'
                  id='login-button'
          >
            Login
          </button>
        </div>
    </div>
  </nav>
</template>

<script>
import NavbarLink from './NavbarLink.vue';

export default {
  name: 'TheNavbar',
  components: { NavbarLink },
  data() {
    return {
      buttonAction: 'Open',
      buttonState: 'is-info',
      buttonIcon: '&#9776;&nbsp;',
    };
  },
  computed: {
    accessAuthorized() {
      return this.$store.state.applicationClaims
        ? this.$store.state.applicationClaims.authorized
        || this.$store.state.applicationClaims.is_admin
        : false;
    },
    linkToSimulation() {
      return `${process.env.VUE_APP_SIMULATION_FRONTEND_URL}/simulate`;
    },
    linkToCompare() {
      return `${process.env.VUE_APP_SIMULATION_FRONTEND_URL}/compare`;
    },
    isAuthenticated() {
      return this.$keycloak.authenticated;
    },
  },
  methods: {
    toggle() {
      document.getElementById('main-navigation').classList.toggle('is-active');
      document.getElementById('navigationBurger').classList.toggle('is-active');
    },

    displaySidebar() {
      this.$emit('displaySidebar');
      if (this.buttonAction === 'Open') {
        this.buttonIcon = '&#10005;&nbsp;';
        this.buttonAction = 'Close';
        this.buttonState = 'is-danger';
      } else {
        this.buttonIcon = '&#9776;&nbsp;';
        this.buttonAction = 'Open';
        this.buttonState = 'is-info';
      }
    },

    async login() {
      await this.$keycloak.login();
    },
    async logout() {
      await this.$keycloak.logout();
    },
  },
};
</script>

<style lang="scss">
#navbar {
  background-color: white;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}

#navbar-logo{
  cursor: pointer;
  width: 5rem;
}

#signIn-buttons {
  padding: 5px;
}

#user-info{
  padding: 10px;
  font-weight: bold;
}

.display-sidebar{
  display: none;
}

@media screen and(max-width: $tablet - 1) {
  .display-sidebar{
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.unavailable:hover {
  cursor: not-allowed;
}
</style>
