<template>
  <tr class="kpi-row" :class="{ selected: selected }">
    <th @click="toggleKpi(name)">
      <label class="checkbox check">
        <input
          type="checkbox"
          id="check"
          class="check"
          :checked="selected"
          :disabled="isDisabled"
        />
      </label>
    </th>
    <td class="kpi-label">
      <span>{{ label }}</span>
    </td>
    <td class="description">{{ description }}</td>
    <td>{{ unit }}</td>
    <td class="threshold">
      <div v-if="!editThreshold" @click="editThreshold = true">{{
        thresholdValue
      }}</div>
      <div v-else class="field">
        <div class="control">
          <input
            class="edit"
            type="number"
            v-model="thresholdValue"
            @blur="editThreshold = false"
            v-focus
          />
        </div>
      </div>
    </td>
    <td>{{ category }}</td>
    <td>{{ kpiType }}</td>
  </tr>
</template>

<script>
export default {
  name: 'KpiRow',

  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    threshold: {
      type: Number,
      required: false,
    },
    category: {
      type: String,
      required: false,
    },
    kpiType: {
      type: String,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      thresholdValue: null,
      editThreshold: false,
      temporalThreshold: null,
    };
  },

  computed: {
    kpiDisplayList() {
      return this.$store.state.overseeKpiPrechooseList;
    },
    selected() {
      return this.kpiDisplayList.includes(this.name);
    },
  },

  beforeMount() {
    this.thresholdValue = this.threshold;
  },

  methods: {
    toggleKpi(name) {
      if (this.selected) {
        this.kpiDisplayList.splice(this.kpiDisplayList.indexOf(name), 1);
      } else {
        this.kpiDisplayList.push(name);
      }
    },
  },

  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.kpi-row {
  border-bottom: 9px solid #fafafa;
}
.kpi-row:hover {
  background-color: #eaeaea;
}
.selected {
  background-color: rgba(3, 79, 161, 0.06) !important;
}
.kpi-label{
  width: 15%;
}
.description{
  width: 40%;
}
.threshold{
  width: 15%;
}
.edit{
  width: 80%;
}
</style>
