<template>
  <div class="box kpi-row has-text-left" :class="{ selected: selected }" v-if="variables !== null">
    <div class="columns is-flex">
      <div class="column is-11">
        <ul>
          <li
            :class="{
              alarm: alarm,
              value: !selected,
              'value-selected': selected,
            }"
          >
            <span class="is-size-3">{{ kpiValue }}</span>
            <span>{{ ' ' + kpiUnit }}</span>
          </li>
          <li class="name is-size-7">{{ kpiLabel }}</li>
        </ul>
      </div>
      <div class="column icons">
        <figure class="image" v-if="icon">
          <img
            :src="require(`@/assets/images/Oversee/kpi_icons/${icon}`)"
            alt=""
          />
        </figure>
        <div class="info" v-if="displayTooltip">
          <span class="icon tooltip-icon">
            <i class="fas fa-info-circle"></i>
          </span>
          <span class="tooltiptext">
            {{ kpiDescription }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KpiDisplayRow',

  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    value: {
      type: Number,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    alarm: {
      type: Boolean,
      required: false,
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
    };
  },

  computed: {
    variables() {
      return this.$store.state.kpiVariables;
    },
    kpiLabel() {
      return this.label === null ? this.theVariable.label : this.label;
    },
    kpiDescription() {
      return this.theVariable.description;
    },
    kpiUnit() {
      if (this.theVariable.type === 'ratio') return '%';
      return this.theVariable.unit;
    },
    kpiValue() {
      if (this.theVariable.type === 'ratio') return this.value * 100;
      if (this.value % 1 === 0) return this.value;
      return this.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    },
    displayTooltip() {
      return this.$store.state.chosenKpiForChart === null;
    },
    theVariable() {
      return this.variables.filter(kpi => (kpi.name === this.name))[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.kpi-row {
  height: 100%;
  padding: 5px 10px !important;
  min-height: max-content !important;
}


.selected {
  background-color: $oversee !important;
  color: white !important;
}

.kpi-row:hover{
  background-color: rgba(117, 117, 117, 0.10);
  cursor: pointer;
}
.alarm {
  color: #ff0000 !important;
  font-weight: bold;
}
.value {
  color: $oversee;
  font-weight: bolder;
}
.value-selected {
  color: white;
  font-weight: bolder;
}
.columns,
.column {
  padding: 0 !important;
  margin: 0 !important;
  margin-bottom: 0 !important;
  height: max-content !important;
}
.icons {
  position: relative;
  min-height: 5.5rem !important;
}
.info {
  position: absolute;
  bottom: 0;
  right: 0;
}
.tooltip-icon {
  color: #d1d1d1;
  cursor: pointer;
}

.info .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #d1d1d1;
  color: black;
  border-radius: 6px;
  position: absolute;
  padding: 5px 0;
  top: 100%;
  left: 50%;
  margin-left: -177px;
  z-index: 1;
  padding: 10px;
  text-align: left;
}

.info:hover .tooltiptext {
  visibility: visible;
}
</style>
