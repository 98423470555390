<template>
  <div class="map-sidebar">
    <ul class="menu-list">
      <li>
        <a
          @click="resetStation(null)"
          :class="{'is-active': overviewChosenStation === null}"
        >
        All Stations
        </a>
        <ul class="menu-list">
          <li>
            <a
              v-for="station in stations"
              :key="station.id"
              @click="resetStation(station.id)"
              :class="{'is-active': overviewChosenStation === station.id}"
            >
              {{ station.name }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MapSidebar',

  props: {
    stations: {
      require: true,
      default: [],
    },
  },

  data() {
    return {
    };
  },

  computed: {
    overviewChosenStation() {
      return this.$store.state.overviewChosenStation;
    },
  },

  methods: {
    resetStation(overviewChosenStation) {
      this.$store.dispatch('setOverviewChosenStation', {
        overviewChosenStation,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.map-sidebar {
  width: 100%;
}
</style>
