<template>
  <div class="container">
    <article class="py-2" @mousedown="closeKpiCategoryDropdown">
      <section class="box mb-3">
        <div class="columns is-flex">
          <ul class="column is-4">
            <li>NAME</li>
            <li class="field">
              <div class="control has-icons-left">
                <input
                  type="search"
                  class="input is-rounded search-bar"
                  placeholder="Type in to search..."
                  autocomplete="off"
                  @click="$event.target.select()"
                  v-model="search"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-search"></i>
                </span>
              </div>
            </li>
          </ul>
          <ul class="column is-4">
            <li>CATEGORY</li>
            <li>
              <div
                class="dropdown"
                :class="{ 'is-active': kpiTypeDropdownActive }"
              >
                <div class="dropdown-trigger">
                  <div
                    class="field"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                  >
                    <div
                      class="control has-icons-left has-icons-right"
                      @click="kpiTypeDropdownActive = !kpiTypeDropdownActive"
                    >
                      <input
                        type="search"
                        class="input is-rounded search-bar"
                        placeholder="Technical, Economic..."
                        autocomplete="off"
                      />
                      <span class="icon is-small is-left">
                        <i class="fas fa-sliders-h"></i>
                      </span>
                      <span class="icon is-small is-right has-text-dark">
                        <i class="fas fa-sort"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="dropdown-menu"
                  id="dropdown-menu"
                  role="menu"
                  @mouseenter="selectingKpiCategory = true"
                  @mouseleave="selectingKpiCategory = false"
                >
                  <div class="dropdown-content">
                    <a
                      class="dropdown-item columns is-flex is-mobile"
                      v-for="(kpiCategory, kpiCategoryIndex) in kpiCategories"
                      @click="toggleKpiCategory(kpiCategory.name)"
                      :key="kpiCategoryIndex"
                    >
                      <div class="column is-4">
                        <span
                          class="icon"
                          v-show="
                            selectedKpiCategories.includes(kpiCategory.name)
                          "
                        >
                          <i class="fas fa-check"></i>
                        </span>
                      </div>
                      <div class="column is-capitalized">
                        {{ kpiCategory.description }}
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="column"></div>
          <div class="column is-2 is-relative">
            <div class="">
              <button
                class="button vertical-middle link-button"
                @click="updateGlobalKpiDisplayList"
              >
                Save and back
              </button>
            </div>
          </div>
        </div>
      </section>
      <section class="level mx-2 mb-3">
        <div class="level-left align-to-end">
          <h4 class="title is-size-6 level-item">
            You have set
            <span
              :class="
                kpiDisplayList.length > 6
                  ? 'kpi-number-overflow'
                  : 'kpi-number-normal'
              "
              >{{ kpiDisplayList.length }}</span
            >/6 KPIs
          </h4>
          <div class="modal" :class="{ 'is-active': kpiOverflowMessageActive }">
            <div class="modal-background"></div>
            <div class="modal-content">
              <article class="message is-warning">
                <div class="message-header">
                  <p>Warning</p>
                  <button
                    class="delete"
                    aria-label="delete"
                    @click="kpiOverflowMessageActive = false"
                  ></button>
                </div>
                <div class="message-body">You may select no more than 6 KPIs</div>
              </article>
            </div>
          </div>
        </div>
      </section>
      <section class="box py-2">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Description</th>
              <th>Unit</th>
              <th>Weekly Threshold</th>
              <th>Category</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            <KpiConfigureRow
              v-for="(kpi, kpiIndex) in filterKpi({
                search: search,
                categories: selectedKpiCategories,
              })"
              :key="kpiIndex"
              :label="kpi.label"
              :name="kpi.name"
              :description="kpi.description"
              :unit="kpi.unit"
              :threshold="getKpiThreshold(kpi.max_threshold, kpi.min_threshold)"
              :category="getCategoryLabel(kpi.category)"
              :kpiType="kpi.type"
            />
          </tbody>
        </table>
      </section>
    </article>
    <section class="mt-3">
      <button
        class="button box link-button"
        @click="updateGlobalKpiDisplayList"
      >
        Save and back
      </button>
    </section>
  </div>
</template>

<script>
import KpiConfigureRow from '@/components/Oversee/Maps/KpiConfigureRow.vue';
import gql from 'graphql-tag';

export default {
  name: 'KpiPage',

  components: {
    KpiConfigureRow,
  },

  data() {
    return {
      categories: null,
      kpiTypeDropdownActive: false,
      search: '',
      selectedKpiCategories: [],
      kpiCategoriesPlaceholder: '',
      selectingKpiCategory: false,
      kpiOverflowMessageActive: false,
      variables: null,
    };
  },

  computed: {
    kpiCategories() {
      return this.categories === null ? [] : this.categories.enumValues;
    },
    kpiDisplayList() {
      return this.$store.state.overseeKpiPrechooseList;
    },
  },

  watch: {
    variables() {
      if (this.$store.state.overseeKpiDisplayList.length === 0 && this.variables !== null) {
        let selectedKpiList = [];
        selectedKpiList = this.variables.slice(0, 6).map(kpi => kpi.name);
        this.$store.dispatch('setOverseeKpiDisplayList', {
          overseeKpiDisplayList: selectedKpiList,
        });
        if (this.$store.state.kpiVariables.length === 0 && this.variables !== null) {
          this.$store.dispatch('setKpiVariables', {
            kpiVariables: this.variables,
          });
        }
      }

      this.$store.dispatch('setOverseeKpiPrechooseList', {
        overseeKpiPrechooseList: this.$store.state.overseeKpiDisplayList.concat(),
      });
    },
  },

  apollo: {
    $client: 'apolloClientMonitoring',
    categories: {
      query: gql`
        query {
          categories: __type(name: "Category") {
            name
            enumValues {
              name
              description
            }
          }
        }
      `,
    },
    variables: {
      query: gql`
        query variables {
          variables {
            id
            name
            label
            description
            unit
            category
            type
            max_threshold
            min_threshold
          }
        }
      `,
    },
  },

  methods: {
    updateGlobalKpiDisplayList() {
      if (this.kpiDisplayList.length <= 6) {
        this.$store.dispatch('setOverseeKpiDisplayList', {
          overseeKpiDisplayList: this.kpiDisplayList,
        });
        this.kpiOverflowMessageActive = false;
        this.$router.push({ name: 'overview' });
      } else {
        this.kpiOverflowMessageActive = true;
      }
    },
    toggleKpiCategory(kpiCategory) {
      if (this.selectedKpiCategories.includes(kpiCategory)) {
        this.selectedKpiCategories.splice(this.selectedKpiCategories.indexOf(kpiCategory), 1);
      } else this.selectedKpiCategories.push(kpiCategory);
    },
    closeKpiCategoryDropdown() {
      if (this.kpiTypeDropdownActive && !this.selectingKpiCategory) {
        this.kpiTypeDropdownActive = false;
      }
    },
    filterKpi({ search = '', categories = [] }) {
      return this.variables === null ? [] : this.variables.filter(
        kpi => (search === ''
            || kpi.label.toLowerCase().includes(search.toLowerCase())
            || kpi.type.toLowerCase().includes(search.toLowerCase()))
          && (categories.length === 0 || categories.includes(kpi.category)),
      );
    },
    getCategoryLabel(categoryName) {
      return this.kpiCategories
        .filter(category => category.name === categoryName)
        .map(category => category.description)[0];
    },
    getKpiThreshold(max, min) {
      return max !== null ? max : min;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 1200px;
  margin: auto;
}
.search-bar {
  background-color: rgba(3, 79, 161, 0.06) !important;
  border: none;
  cursor: pointer;
}
.vertical-middle {
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translate(0, -50%);
}
th {
  vertical-align: middle !important;
  color: $setting-button !important;
}
.py-2 {
  padding: 1rem 0;
}
.mx-2 {
  margin: 0 1rem;
}
.kpi-name {
  margin-left: 3rem;
}
.kpi-header {
  color: grey;
}
.kpi-number-normal {
  color: $oversee;
  margin-left: 0.25rem;
}
.kpi-number-overflow {
  color: #ff0000;
  margin-left: 0.25rem;
}
.dropdown-item {
  margin: 0 !important;
  user-select: none;
  padding: 0 1rem;
}
.aligne-to-end {
  align-self: flex-end;
}
a:hover {
  background-color: $oversee !important;
  color: white !important;
}
.alert-massage{
  background-color: rgba(240, 245, 249, 1);
}
.alert-massage .message-header{
  background-color: $oversee !important;
  color: white;
}
</style>
