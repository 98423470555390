<template>
  <div class="is-relative" v-if="this.station !== null">
    <figure class="image">
      <img
        :src="require(`@/assets/images/Stations/${pidImage}`)"
        alt="station_image"
      />
      <H2Flow
        v-for="(flow, flowIndex) in pid.flows"
        :active="flowActivation(flow)"
        :key="flowIndex"
        :direction="flow.direction"
        :length="flow.length"
        :style="getProgressBarStyle(flow.top, flow.left, flow.length)"
      />
    </figure>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import H2Flow from '@/components/Oversee/Pfds/H2Flow.vue';
import { getPid } from '@/assets/data/queries';

export default {
  name: 'Pfd',

  components: {
    H2Flow,
  },

  data() {
    return {
      station: null,
      progressActive: true,
    };
  },

  computed: {
    stationId() {
      return this.$route.params.id;
    },
    pid() {
      return getPid({ name: this.station.name })[0];
    },
    pidImage() {
      return this.pid.pid === '' ? 'station1_torrance.png' : this.pid.pid;
    },
  },

  apollo: {
    $client: 'apolloClientMonitoring',
    station: {
      query: gql`
        query station($id: String!) {
          station(id: $id) {
            id
            name
            source_available
            dispenser_available
            core_available
          }
        }
      `,
      variables() {
        return {
          id: this.stationId,
        };
      },
    },
  },

  methods: {
    getProgressBarStyle(top, left, width) {
      return `left: ${left}%; top: ${top}%; width: ${width}%`;
    },
    flowActivation(flow) {
      if (flow.distribution) {
        return (
          this.station.dispenser_available && this.station.core_available && this.progressActive
        );
      }
      return this.station.source_available && this.station.core_available && this.progressActive;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
