<template>
  <div>
    <div v-if="station !== null">
      <div class="columns is-flex section-block">
        <div class="column">
          <div
            class="box section-menu"
            :class="{ alarmed: !station.source_available }"
          >
            Source
            <PidIndicater
              :subSystem="station.source_available"
              class="pid-indicater"
            />
          </div>
        </div>
        <div class="column">
          <div
            class="box section-menu"
            :class="{
              selected: routeSection === 'core',
              alarmed: !station.core_available,
            }"
            @click="toggleSection('core')"
          >
            Core
            <PidIndicater
              :subSystem="station.core_available"
              class="pid-indicater"
            />
          </div>
        </div>
        <div class="column">
          <div
            class="box section-menu"
            :class="{ alarmed: !station.dispenser_available }"
          >
            Dispensing
            <PidIndicater
              :subSystem="station.dispenser_available"
              class="pid-indicater"
            />
          </div>
        </div>
      </div>
      <router-view></router-view>
    </div>
    <div v-else>
      <h1 class="title">Loading</h1>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { getPid } from '@/assets/data/queries';
import PidIndicater from '@/components/Oversee/Pfds/PidIndicater.vue';

export default {
  name: 'OverseeStation',
  components: {
    PidIndicater,
  },

  data() {
    return {
      station: null,
      progressActive: true,
    };
  },

  computed: {
    stationId() {
      return this.$route.params.id;
    },
    routeSection() {
      return this.$route.params.section;
    },
    pid() {
      return getPid({ name: this.station.name })[0];
    },
  },

  apollo: {
    $client: 'apolloClientMonitoring',
    station: {
      query: gql`
        query station($id: String!) {
          station(id: $id) {
            id
            name
            source_available
            dispenser_available
            core_available
          }
        }
      `,
      variables() {
        return {
          id: this.stationId,
        };
      },
    },
  },

  methods: {
    toggleSection(sectionName) {
      if (this.pid.core) {
        if (this.routeSection !== sectionName || this.routeSection === undefined) {
          this.$router.push({
            name: 'sectionpfd',
            params: { section: sectionName, station: this.station.name },
          });
        } else {
          this.$router.push({ name: 'pfd' });
        }
      }
    },
    backToAll() {
      if (this.routeSection !== undefined) {
        this.$router.push({ name: 'pfd' });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.section-block{
  margin: 0;
}
.section-menu {
  height: 4rem;
  width: 50%;
  min-width: fit-content;
  margin-left: 7%;
  text-align: center;
  user-select: none;
  position: relative;
}
.all-button:hover, .section-menu:hover {
  background-color: rgba(117, 117, 117, 0.10);
}
.selected{
  background-color: $oversee !important;
  color: white !important;
}

.pid-indicater {
  top: -0.9rem;
  right: -0.9rem;
}

.alarmed {
  border: solid 4px #e71a1a;
}
</style>
