<template>
  <footer class="footer">
    <div class="container is-max-desktop level is-mobile">
      <div class="level-left">
        <a
          class="level-item"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.pers-ee.com/"
        >
          <img src="@/assets/images/logo-persee.svg" class="logo" alt="logo" />
        </a>
      </div>
      <div class="level-right pl-2 smaller-text">
        <a class="level-item has-text-white" @click="cookieModal = true">
          <span>Cookie policy</span>
        </a>
        <a class="level-item has-text-white" @click="$router.push('/legal-statement')">
          <span>Legal statement</span>
        </a>
        <div class="level-item">
          <span>&#169; 2020 Persee</span>
        </div>
      </div>
    </div>
    <div class="cookie-modal">
      <div class="modal" :class="{ 'is-active': cookieModal }">
        <div class="modal-background" @click="cookieModal = false"></div>
        <div class="modal-content">
          <div class="cookie-popup has-background-white">
            <figure class="image image is-128x128">
              <img src="@/assets/images/cookie.svg" alt="" />
            </figure>
            <h2 class="title">Our Cookie Policy</h2>
            <p>
              Cookies are tiny files that are downloaded to your computer, to
              improve your experience. We don’t use cookies here, we prefer to
              eat them !
            </p>
            <button
              class="button is-small is-rounded is-success mb-6"
              @click="cookieModal = false"
            >
              Got it !
            </button>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',

  data() {
    return {
      cookieModal: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  padding: 0px;
  height: 5rem !important;
}
.footer .logo{
  height:4rem;
}
.level {
  padding: 0 !important;
  z-index: 0;
}
.level,
.level-left,
.level-item,
.level-item img {
  height: 100%;
}
.smaller-text {
  font-size: 0.8rem;
}
.modal-content {
  width: 620px !important;
}
.cookie-popup {
  border: solid 1.5px $sophy-blue;
  padding: 10px;

}
.cookie-popup figure{
  padding: 0;
  margin: 2rem auto;
}
.cookie-popup p{
  width: 85%;
  margin: 1.5rem auto;
  color: black;
}
@media screen and (max-width: 1023px) {
  .smaller-text {
    font-size: 0.55rem;
    padding-top: 0.25rem;
  }
  .modal-content {
    max-width: 100% !important;
  }
}
</style>
