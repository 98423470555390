<template>
  <div class="legends" v-if="show">
    <ul>
      <li>
        <div class="legend box">
          <div class="columns is-vcentered">
            <div class="column is-4">
              <figure class="image medium">
                <img
                  src="@/assets/images/Oversee/marker-available.svg"
                  alt=""
                />
              </figure>
            </div>
            <div class="column">
              <p class="legend-text">Available</p>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="legend box">
          <div class="columns is-vcentered">
            <div class="column is-4">
              <figure class="image medium">
                <img
                  src="@/assets/images/Oversee/marker-unavailable.svg"
                  alt=""
                />
              </figure>
            </div>
            <div class="column">
              <p class="legend-text">Unavailable</p>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AvailabilityLegends',

  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.legends {
  background-color: white;
  text-align: left;
  width: 9rem;
  margin: 1rem;
}
.legend {
  background-color: #f1efef;
  margin-bottom: 1rem;
  padding: 0.8rem;
}
.medium {
  height: 36px;
  width: 30px;
  margin: auto;
}
.legend-text {
  text-align: center;
}
.legend-icon {
  margin: auto;
}
</style>
