<template>
<div>
  <div class="oversee-interface" :class="{'display-chart': displayChart}">
    <div class="columns oversee-content" >
    <div class="column">
      <div class="map-wrapper">
      <TheStationMap/>
      </div>
    </div>
    <div class="column is-5" v-if="overviewChosenStation !== null">
      <TheOverseeKpis />
    </div>
  </div>
  </div>
  <div id="chart" class="box chart mt-2" v-if="displayChart">
    <TheKpiChart/>
  </div>
  </div>
</template>

<script>
import TheStationMap from '../../components/Oversee/Maps/TheStationMap.vue';
import TheOverseeKpis from '../../components/Oversee/Maps/TheOverviewKpis.vue';
import TheKpiChart from '../../components/Oversee/Maps/TheKpiChart.vue';

export default {
  name: 'OverseeInterface',

  components: { TheOverseeKpis, TheStationMap, TheKpiChart },

  data() {
    return {
      capacity: false,
    };
  },

  computed: {
    displayChart() {
      return this.$store.state.chosenKpiForChart !== null && this.overviewChosenStation !== null;
    },
    overviewChosenStation() {
      return this.$store.state.overviewChosenStation;
    },
  },

  watch: {
    // overviewChosenStation() {
    //   this.$store.dispatch('setChosenKpiForChart', {
    //     chosenKpiForChart: null,
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.map-wrapper {
  height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
}
.oversee-interface{
  height: 90vh;
  transition: height 0.5s ease-in-out;
}
.oversee-content{
  height: 100%;
}
.display-chart {
  height: 50vh !important;
}
.chart{
  border-radius: 13px !important;
  transition: height 0.5s ease-in-out;
  height: 40vh !important;
}
</style>
