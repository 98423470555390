import Vue from 'vue';
import VueRouter from 'vue-router';
import TheAbout from './views/TheAbout.vue';
import OverseeContainer from './views/Maps/OverseeContainer.vue';
import PfdContainer from './views/Pfds/PfdContainer.vue';
import OverseeInterface from './views/Maps/OverseeInterface.vue';
import PfdInterface from './views/Pfds/PfdInterface.vue';
import StationView from './views/Pfds/StationView.vue';
import KpiPage from './views/Maps/KpiPage.vue';
import Pfd from './views/Pfds/Pfd.vue';
import SectionPfd from './views/Pfds/SectionPfd.vue';
import LegalStatement from './views/LegalStatement.vue';
import NotFound from './views/NotFound.vue';


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: { name: 'overview' },
  },
  {
    path: '/maps-and-kpis',
    component: OverseeContainer,
    name: 'maps&kpis',
    children: [
      { path: '/', name: 'overview', component: OverseeInterface },
      { path: 'about', name: 'aboutOversee', component: TheAbout },
      { path: 'kpi', name: 'kpiPage', component: KpiPage },
    ],
  },
  {
    path: '/pfds',
    component: PfdContainer,
    name: 'pfds',
    children: [
      {
        path: 'pfd-description',
        component: PfdInterface,
        name: 'pfd-description',
      },
      {
        path: 'station/:id',
        component: StationView,
        name: 'station',
        children: [
          {
            path: 'pfd',
            name: 'pfd',
            component: Pfd,
          },
          {
            path: 'section-pfd/:section',
            name: 'sectionpfd',
            component: SectionPfd,
          },
        ],
      },
    ],
  },
  {
    path: '/legal-statement',
    name: 'legal-statement',
    component: LegalStatement,
  },
  {
    path: '/*',
    name: 'NotFound',
    component: NotFound,
  },
];

export default new VueRouter(
  {
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  },
);
