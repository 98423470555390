<template>
  <div class="sensor" :class="{ 'alarm-high': value > ah, 'alarm-low': value < al }">
    {{ type +' '+ value + ' ' +unit }}
  </div>
</template>

<script>
export default {
  name: 'SectionPfdSensor',

  props: {
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      require: true,
    },
    max: {
      type: Number,
      required: false,
      default: 100,
    },
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    ah: {
      type: Number,
      required: false,
      default: 100,
    },
    al: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  data() {
    return {
      interval: null,
      value: 0,
    };
  },

  computed: {
    unit() {
      return this.type === 'P' ? 'bar' : '°C';
    },
  },

  mounted() {
    this.value = this.getRandomValue(this.min, this.max);
    this.setConfigs();
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    setConfigs() {
      this.interval = setInterval(() => {
        this.value = this.getRandomValue(this.min, this.max);
      }, 3000);
    },
    getRandomValue(min = 0, max = 100) {
      return (Math.random() * (max - min) + min).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.sensor {
  position: absolute;
  min-width: 5vw;
  text-align: left;
  font-size: 1vw;
  font-weight: bold;
  color: #707070;
}
.alarm-high {
  color: #C43701;
}
.alarm-low {
  color: #FFA400;
}
</style>
