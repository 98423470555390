<template>
  <div class="valve">
    <div class="label mb-0">
      {{ name.toUpperCase() }}
    </div>
    <div class="indicator" :class="{ 'open': value > 50, 'close': value <= 50 }">
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionPfdValve',

  props: {
    name: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      interval: null,
      value: 0,
    };
  },

  mounted() {
    this.setConfigs();
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    setConfigs() {
      this.interval = setInterval(() => {
        this.value = this.getRandomValue();
      }, 3000);
    },
    getRandomValue(min = 0, max = 100) {
      return (Math.random() * (max - min) + min).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.valve {
  position: absolute;
  min-width: 5vw;
  text-align: center;
  font-size: 1vw;
}
.valve .label{
  font-size: 1vw;
}
.indicator{
  border-radius: 2px;
  height: 1vw;
  width: 100%;
}
.open{
  background-color: green;
}
.close{
  background-color: grey;
}
</style>
