import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import Keycloak from 'keycloak-js';
import App from './App.vue';
import router from './router';
import store from './store';

require('./assets/sass/main.scss');
// eslint-disable-next-line
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  // eslint-disable-next-line
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  // eslint-disable-next-line
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  // eslint-disable-next-line
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

library.add(fas);
library.add(far);
dom.watch();

const initOptions = {
  url: process.env.VUE_APP_AUTH_SERVER_URL,
  realm: process.env.VUE_APP_AUTH_REALM,
  clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
  onLoad: 'login-required',
};

const keycloak = Keycloak(initOptions);
Vue.prototype.$keycloak = keycloak;

const httpLinkMonitoring = createHttpLink({
  // You should use an absolute URL here
  uri: `${process.env.VUE_APP_MONITORING_API_BASE_URL}/graphql`,
});

const authLink = new ApolloLink((operation, forward) => {
  const { token } = keycloak;
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });
  return forward(operation);
});

const cacheMonitoring = new InMemoryCache();

const apolloClientMonitoring = new ApolloClient({
  link: authLink.concat(httpLinkMonitoring),
  cache: cacheMonitoring,
  connectToDevTools: true,
});


const apolloProvider = new VueApollo({
  clients: {
    apolloClientMonitoring,
  },
  defaultClient: apolloClientMonitoring,
});

Vue.prototype.$unitconverter = require('convert-units');

Vue.use(VueApollo);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;

// keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
//   if (!auth) {
//     window.location.reload();
//   } else {
new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App),
}).$mount('#app');
// }
// // Token Refresh
// setInterval(() => {
//   keycloak.updateToken(30).then()
//     .catch(() => {
//       console.error('Failed to refresh token. Session has expired');
//       keycloak.clearToken();
//       keycloak.logout();
//     });
// }, 10000);
// }).catch(() => {
//   console.error('Authenticated Failed');
// });
