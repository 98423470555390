  <template>
  <div>
    <router-view class="interface"></router-view>
  </div>
</template>

<script>
export default {
  name: 'PfdContainer',

  data() {
    return {
    };
  },

  computed: {
  },
};
</script>

<style lang="scss" scoped>
.interface {
  min-height: 90vh;
}
</style>
