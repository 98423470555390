import { render, staticRenderFns } from "./SectionPfd.vue?vue&type=template&id=ea41cfc4&scoped=true&"
import script from "./SectionPfd.vue?vue&type=script&lang=js&"
export * from "./SectionPfd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea41cfc4",
  null
  
)

export default component.exports