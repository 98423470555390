<template>
  <div class="sidebar column is-3 is-2-widescreen" id="main-sidebar" v-if="!hideSidebar">
    <MapSidebar v-if="$route.matched[0].name === 'maps&kpis'" :stations="stations"/>
    <PfdSidebar v-else-if="$route.matched[0].name === 'pfds'" :stations="stations"/>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import MapSidebar from '@/components/Oversee/Maps/MapSidebar.vue';
import PfdSidebar from '@/components/Oversee/Pfds/PfdSidebar.vue';

export default {
  name: 'TheSidebar',

  components: {
    MapSidebar,
    PfdSidebar,
  },

  data() {
    return {
      stations: null,
    };
  },

  computed: {
    hideSidebar() {
      return this.$route.matched[1] === undefined || this.$route.matched[1].name === 'kpiPage';
    },
  },

  apollo: {
    $client: 'apolloClientMonitoring',
    stations: {
      query: gql`
        query stations {
          stations {
            id
            name
          }
        }
      `,
    },
  },

  methods: {
    resetStation(overviewChosenStation) {
      this.$store.dispatch('setOverviewChosenStation', {
        overviewChosenStation,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: $tablet) {
  .sidebar {
    position: sticky;
    height: 100vh;
    top: 0;
  }
}
@media screen and (max-width: $tablet - 1) {
  .sidebar {
    position: absolute;
    z-index: 5;
    width: 50%;
    max-height: 70%;
  }
}
.sidebar {
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}
</style>
