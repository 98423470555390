<template>
  <div
    class="progress"
    :class="{
      'direction-down': direction === 'down',
      'direction-up': direction === 'up',
    }"
  >
    <div v-if="active && !long" id="pointer1"></div>
    <div v-if="active && long" id="pointer2"></div>
  </div>
</template>

<script>
export default {
  name: 'H2Flow',

  props: {
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
    direction: {
      type: String,
      required: false,
    },
    length: {
      type: Number,
      required: true,
    },
  },

  computed: {
    long() {
      return this.direction !== '';
    },
  },
};
</script>

<style lang="scss" scoped>
.progress {
  justify-content: flex-start;
  border-radius: 0px;
  align-items: center;
  position: relative;
  padding: 0 5px;
  display: flex;
  height: 2%;
  margin: 0 !important;
  position: absolute;
}

.direction-down {
  transform: rotate(90deg);
}

.direction-up {
  transform: rotate(270deg);
}

#pointer1{
  animation-name: flow;
  animation-duration: 10s;
  animation-delay: 0s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  height: 60%;
  width: 80%;
  position: absolute;
  left: -80%;
  background: white;
}
#pointer2{
  animation-name: flow;
  animation-duration: 10s;
  animation-delay: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  height: 60%;
  width: 80%;
  position: absolute;
  left: -80%;
  background: white;
// border-radius: 0 50% 50% 0;
}
// #pointer1::after, #pointer2::after {
//   content: "";
//   position: absolute;
//   left: -1px;
//   bottom: 0;
//   width: 0;
//   height: 0;
//   border-left: 30% solid #AEAEAF;
//   border-top: 30% solid transparent;
//   border-bottom: 30% solid transparent;
// }
// #pointer1::before, #pointer2::before {
//   content: "";
//   position: absolute;
//   right: -8px;
//   bottom: 0;
//   width: 0;
//   height: 0;
//   border-radius: 0 0 50% 50%;
// }
@keyframes flow {
  0% {
   left: -80%;
  }
  100% {
    left: 100%;
  }
}
</style>
