<template>
    <router-link
      :class="{'is-active': isActive}"
      :to="disabledLink"
      v-html="label"
    />
</template>

<script>
export default {
  name: 'SidebarLink',

  props: {
    to: String,
    label: String,
    id: String,
    idParam: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    goTo() {
      return this.idParam
        ? {
          name: this.to,
          params: this.setParams(),
        }
        : {
          name: this.to,
        };
    },

    disabledLink() {
      return this.disabled ? '' : this.goTo;
    },

    isActive() {
      const isCurrentRoute = this.$route.name === this.to;
      return this.idParam
        ? this.$route.params[this.idParam] === this.id
        : isCurrentRoute;
    },
  },

  methods: {
    setParams() {
      return { [this.idParam]: this.id };
    },
  },
};
</script>

<style scoped>
</style>
