<template>
  <div
    id="app"
    class="hero is-fullheight"
  >
    <div class="hero-head">
      <TheNavbar @displaySidebar="showSidebar()" />
    </div>

    <div
      id="main"
      class="hero-body"
    >
      <div class="columns">
          <TheSidebar id="main-sidebar"
                      v-if="isSidebarVisible($route.name)"
          />
          <div
            id="main-content"
            class="column"
          >
            <router-view />
          </div>
      </div>
    </div>

    <div class="hero-foot">
      <footer class="footer">
      <TheFooter />
      </footer>
    </div>
  </div>
</template>

<script>
import { parseISO } from 'date-fns';
import TheNavbar from './components/TheNavbar.vue';
import TheSidebar from './components/TheSidebar.vue';
import TheFooter from './components/TheFooter.vue';

export default {
  name: 'App',
  components: {
    TheSidebar,
    TheNavbar,
    TheFooter,
  },

  data() {
    return {
      windowWidth: 0,
      windowHeight: 0,
    };
  },

  /* TODO: check listeners (reimplement if necessary) */
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getWindowWidth);
      this.getWindowWidth();
    });
    this.setup();

    const currentDate = process.env.VUE_APP_MONITORING_CURRENT_TIME === undefined
      ? new Date()
      : parseISO(process.env.VUE_APP_MONITORING_CURRENT_TIME);
    this.$store.commit('setDates', currentDate);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
  },

  methods: {
    isSidebarVisible(currentRoute) {
      const exludedRoutes = ['logincallback', 'legal-statement'];
      return !exludedRoutes.includes(currentRoute);
    },
    showSidebar() {
      const mainSidebar = document
        .getElementById('main-sidebar');
      const mainContent = document
        .getElementById('main-content');
      if (mainSidebar.style.display === 'none' || mainSidebar.style.display === '') {
        mainSidebar.style.display = 'inherit';
        mainContent.style.position = 'relative';
      } else {
        mainSidebar.style.display = 'none';
        mainContent.style.position = 'relative';
      }
    },

    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
      if (this.windowWidth <= 768) {
        try {
          document.getElementById('main-sidebar').style.display = 'none';
        } catch (error) {
          console.warn('Unloaded Component');
        }
      } else {
        try {
          document.getElementById('main-sidebar').style.display = 'inherit';
        } catch (error) {
          console.warn('Unloaded Component');
        }
      }
    },

    async setup() {
      const claims = this.$keycloak.tokenParsed;
      this.$set(claims, 'is_admin', claims.groups.includes('/persee'));
      this.$store.commit('setApplicationClaims', claims);
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto');

#main {
  padding: 0;
  flex-direction: column;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#main > div {
  flex: 1;
  margin: 0;
  width: 100%;
}

footer {
  text-align: center;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: $oversee;
  transition: 0.3s ease;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: findDarkColor($oversee);
}

@media screen and (max-width: $tablet - 1){
  #main-content{
    position: relative;
  }
}
</style>
