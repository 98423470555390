<template>
  <router-link
    :to="{ name: disabledLink }"
    class="navbar-item"
    :class="isCurrentTab(name)"
  >
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: 'NavbarLink',

  props: {
    to: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    // must match the name of the route
    name: {
      type: String,
      required: true,
    },
  },

  computed: {
    disabledLink() {
      return this.disabled ? '' : this.to;
    },
  },

  methods: {
    isCurrentTab(tabName) {
      if (this.$route.matched[0]) {
        return this.$route.matched[0].name.localeCompare(tabName) ? '' : 'is-active';
      }
      return '';
    },
  },
};
</script>

<style scoped>
</style>
