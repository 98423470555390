  <template>
  <div>
    <router-view class="interface"></router-view>
    <div class="modal" v-if="overseeDisplayModal" :class="{'is-active': modal}">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"></p>
        </header>
        <section class="modal-card-body">
          <!-- Content ... -->
          <h1 class="title is-size-4">Welcome to FilHy Monitoring</h1>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="terminateModal">Got it</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OverseeContainer',

  data() {
    return {
      modal: false,
    };
  },

  computed: {
    overseeDisplayModal() {
      return this.$store.state.overseeDisplayModal;
    },
  },

  methods: {
    terminateModal() {
      this.modal = false;
      this.$store.dispatch('setOverseeDisplayModal', {
        overseeDisplayModal: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.interface {
  min-height: 90vh;
}
</style>
