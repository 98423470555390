<template>
  <div class="pfd-interface">
    <div class="notification description content">
      <p>
      This section comprises Process Flow Diagrams (PFDs) that illustrate the relationships
       between the components at a Hydrogen Refueling Station (HRS). It aims to document a
        process for better understanding, communicating and collaborating with diagrams that
         speak to various roles.
    </p>
    <p>
      FilHy further breakdowns the PFDs of a typical HRS into three main sections:
       <strong>source</strong> (delivered and/or on-site produced hydrogen),
       <strong>core</strong>:
      (compression and storage) and <strong>dispensing</strong> (cooling and distribution).
    </p>
    <p>
      You will be able to dive into detailed operational data by clicking on the stations’
       main section. Also, if there are any critical and/or significant alarms in place these
        will be highlighted.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PfdInterface',

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.pfd-interface {
  height: fit-content;
  min-height: 90vh;
}
.description {
  width: 90%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  text-align: left;
  background-color: $oversee;
  color: white;
}
</style>
