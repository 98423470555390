<template>
  <div class="legends" v-if="show">
    <!-- <div class="">Theoretical capacity</div> -->
    <ul>
      <li>
        <div class="legend box">
          <div class="columns is-vcentered mx-0">
            <div class="column is-5">
              <figure class="image large">
                <img src="@/assets/images/Oversee/markerlarge.svg" alt="" />
              </figure>
            </div>
            <div class="column">
              <span>&gt; 1000 kg/day</span>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="legend box">
          <div class="columns is-vcentered mx-0">
            <div class="column is-5">
              <figure class="image medium">
                <img src="@/assets/images/Oversee/markermedium.svg" alt="" />
              </figure>
            </div>
            <div class="column">
              <span>350-1000 kg/day</span>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="legend box">
          <div class="columns is-vcentered mx-0">
            <div class="column is-5">
              <figure class="image small">
                <img src="@/assets/images/Oversee/markersmall.svg" alt="" />
              </figure>
            </div>
            <div class="column">
              <span>&lt; 350 kg/day</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <!-- <div class="">Pressure</div> -->
    <ul>
      <li>
        <div class="legend box">
          <div class="columns is-vcentered mx-0">
            <div class="column is-5">
              <figure class="image legend-icon medium">
                <img src="@/assets/images/Oversee/marker350.svg" alt="" />
              </figure>
            </div>
            <div class="column">
              <span>350 bar</span>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="legend box">
          <div class="columns is-vcentered mx-0">
            <div class="column is-5">
              <figure class="image legend-icon medium">
                <img src="@/assets/images/Oversee/marker700.svg" alt="" />
              </figure>
            </div>
            <div class="column">
              <span>700 bar</span>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="legend box">
          <div class="columns is-vcentered mx-0">
            <div class="column is-5">
              <figure class="image legend-icon medium">
                <img src="@/assets/images/Oversee/markermix.svg" alt="" />
              </figure>
            </div>
            <div class="column">
              <span>350 and 700 bar</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CapacityLegends',

  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.legends {
  background-color: white;
  text-align: left;
  width: 10rem;
  margin: 0.5rem 1rem 0;
}
.legend {
  background-color: #f1efef;
  margin-bottom: 1rem;
  padding: 0.4rem;
  min-height: 3rem;
  display: flex;
}
.columns{
  width: 100%;
}
.large {
  height: 50px;
  width: 50px;
  margin: auto;
}
.medium {
  height: 36px;
  width: 36px;
  margin: auto;
}
.small {
  height: 20px;
  width: 20px;
  margin: auto;
}
.legend-icon {
  margin: auto;
}
.legend-head {
  background-color: #eaeaea;
  text-align: center;
  padding: 0.75rem;
}
</style>
